import { Box, Divider, Typography } from '@mui/material';
import React, {Fragment} from 'react';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BlackButton from '../../../atoms/BlackButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import dynamic from 'next/dynamic';
import ContactForm from '../../../molecules/ContactForm4Ad';
import Link from 'next/link';
import { connect, useSelector } from 'react-redux';
import Accordion from '../../../molecules/Accordion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import scrollToAnchor from '../../../../utils/scrollToAnchor';
import numberWithSpaces from '../../../../utils/numberWithSpaces';
import Head from 'next/head';
import {
  srcset,
  getObjectHousingAssociation,
  getObjectArea,
  getObjectCostPerYear,
  getObjectDocuments,
  getObjectBuilding,
  getObjectRealEstate,
  getObjectSummary,
  getAccordionData,
  getImportantInformation,
  swedishDay,
  swedishMonth
} from "./houseHelpers";
import {ObjectBaseInfo} from "../../../../types/object";
import YouTubeVideos from "./YouTubeVideos";
import {AppState} from "../../../../redux/store";

const menuItem = (name: string, id: string) => (
  <li style={{ display: 'flex', alignItems: 'center' }}>
    <AnchorLink
      offset="45"
      href={`#${id}`}
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <Typography
        component="p"
        sx={{
          fontSize: '20px',
          fontFamily: 'Roboto-Light',
          textTransform: 'uppercase',
          textDecoration: 'none',
          pr: '10px',
        }}
      >
        {name}
      </Typography>
      <Box
        sx={{ width: '100%', height: '2px', backgroundColor: 'darkgreen' }}
        className="current-bar"
      />
    </AnchorLink>
  </li>
);

const HouseInfo = (props) => {

  const {
    houseAssociationTypes,
    coaAssociationTypes,
    municipalities,
    houseTypes,
    biddingInfo,
  } = props;

  const objectData = props.data;

  const objectImages = [...objectData.images];
  objectImages.sort((a, b) => {
    if (a.Type < b.Type) return -1;
    if (a.Type > b.Type) return 1;
    if (a.ImageOrder < b.ImageOrder) return -1;
    if (a.ImageOrder > b.ImageOrder) return 1;
    return 0;
  });
  const [bidding, setBidding] = React.useState(null);
  const [demoBids, setDemoBids] = React.useState([]);

  const [selectedMenu, setSelectedMenu] = React.useState(-1);

  const refObjectGeneral = React.useRef(null);
  const [refVisible, setRefVisible] = React.useState(false);
  const [showObjectGeneral, setShowObjectGeneral] = React.useState(false);

  const [imagesModalOpen, setImagesModalOpen] = React.useState(0);

  const [contactRadio, setContactRadio] = React.useState('2');
  const [contactMessage, setContactMessage] = React.useState('');

  const [bidsOpened, setBidsOpened] = React.useState(false);
  const [bidsModal, setBidsModal] = React.useState(false);
  const [biddingModal, setBiddingModal] = React.useState(false);
  const refContainer = React.useRef();

  React.useEffect(() => {
    scrollToAnchor();
    const res = biddingInfo.find((bid) => bid.id === objectData.BaseID);
    if (res === undefined) {
      setBidding({
        bids: [],
        highestBid: null,
        biddingOngoing: false,
        allowOnlineBidding: false,
        nBidStatusId: -1,
        bStopped: false,
      });
      setDemoBids([]);
    } else {
      setBidding(res);
      setDemoBids(
        !res || !res.bids || res.bids.length === 0
          ? []
          : res.bids
              .map((item) => {
                return {
                  price: item.nAmount,
                  date: new Date(item.dtPlacedAt),
                  bidder: item.nBidderNumber,
                  nBidStatusId: item.nBidStatusId ?? -1,
                  bStopped: item.bStopped ?? false,
                };
              })
              .sort((a, b) => (a.price < b.price ? 1 : -1))
      );
    }
  }, [objectData]);

  const objectSummary = getObjectSummary(houseTypes, objectData, coaAssociationTypes, houseAssociationTypes);
  let objectRooms = [...objectData.rooms];
  objectRooms = objectRooms
    .sort((a, b) => (a.nSortOrder > b.nSortOrder ? 1 : -1))
    .map((item) => {
      return {
        name: item.sName,
        desc: item.sDescription,
      };
    });
  const objectBuilding = getObjectBuilding(houseTypes, objectData);
  const objectRealEstate = getObjectRealEstate(objectData, coaAssociationTypes, houseAssociationTypes);
  const objectHousingAssociation = getObjectHousingAssociation(objectData);
  const objectArea = getObjectArea(objectData);
  const objectCostPerYear = getObjectCostPerYear(objectData);
  const objectDocuments = getObjectDocuments(objectData);
  const importantInformation = getImportantInformation(objectData);
  const accordionData = getAccordionData(objectSummary, objectData, objectRooms, objectBuilding, objectRealEstate, objectHousingAssociation, objectArea, objectCostPerYear, objectDocuments, importantInformation);
  const itemData = [
    {
      img: objectImages[1]?.FileURL,
      title: 'Image 1',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[2]?.FileURL,
      title: 'Image 2',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[3]?.FileURL,
      title: 'Image 3',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[0]?.FileURL,
      title: 'Image 4',
      cols: 2,
      rows: 2,
    },
    {
      img: objectImages[4]?.FileURL,
      title: 'Image 5',
    },
    {
      img: objectImages[5]?.FileURL,
      title: 'Image 6',
    },
  ];
  const objectGeneral = [
    {
      type: 'KOMMUN',
      value:
        municipalities.find(
          (municipality) =>
            municipality.MunicipalityID === objectData.MunicipalityID
        ).Name ?? '',
    },
    {
      type: 'OMRÅDE',
      value: objectData.AreaName ?? '',
    },
    {
      type: 'BYGGÅR',
      value:
        objectData.TypeID === 1 && objectData.HouseTypeId !== 11
          ? objectData.ConstructionYear ?? ''
          : '',
    },
    {
      type: 'Upplåtelseform',
      value:
        objectData.AssociationTypeID === null
          ? null
          : objectData.TypeID === 0
          ? coaAssociationTypes.find(
              (f) => f.AssociationTypeID === objectData.AssociationTypeID
            ).Type ?? null
          : houseAssociationTypes.find(
              (f) => f.TypeId === objectData.AssociationTypeID
            ).TypeName ?? null,
    },
    {
      type: 'Boendeform',
      value:
        houseTypes.find((type) => type.HouseTypeID === objectData.HouseTypeId)
          .Type ?? '',
    },
    {
      type: 'Boarea',
      value: objectData.Area ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative', mr: 2 }}>
            {`${objectData.Area} m `}
            <sup style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
    {
      type: 'Biarea',
      value: objectData.BiArea ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative', mr: 2 }}>
            {`${objectData.BiArea} m `}
            <sup style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
    {
      type: 'Rum',
      value: objectData.NumberOfRooms ? objectData.NumberOfRooms + ' rum' : '',
    },
    {
      type: 'Månadsavgift',
      value: objectData.MonthlyFee
        ? numberWithSpaces(objectData.MonthlyFee) + ' kr'
        : '',
    },
    {
      type: 'Våning',
      value:
        objectData.FloorNumber && objectData.FloorTotal
          ? objectData.FloorNumber +
            ' av ' +
            objectData.FloorTotal +
            (objectData.Elevator ? ' (hiss finns)' : '')
          : '',
    },
    {
      type: 'tomtarea',
      value: objectData.LotArea ? (
        <>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ position: 'relative' }}>
              {`${objectData.LotArea} m `}
              <sup
                style={{ position: 'absolute', top: '-5px', right: '-10px' }}
              >
                2
              </sup>
            </Box>
          </Box>
        </>
      ) : (
        ''
      ),
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactRadio((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setImagesModalOpen(0);
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    if (selectedMenu === index) setSelectedMenu(-1);
    else setSelectedMenu(index);
  };
  const MapWithNoSSR = dynamic(() => import('../map'), {
    ssr: false,
  });

  const emailShareSubject = encodeURIComponent(
    `${objectData.Address}, ${objectData.PostalAddress} - Privatmäklaren`
  );
  const emailShareBody = encodeURIComponent(
    `Hej,\nJag vill tipsa dig om en bostad som är till salu på ${
      objectData.Address
    }, ${
      objectData.PostalAddress
    } hos Privatmäklaren.\nHär kan du läsa mera: \n${
      process.env.NEXT_PUBLIC_DOMAIN_URL + '/' + objectData.Slug
    }`
  );

  const map = (
    <Box id="karta">
      <Typography
        sx={{
          fontFamily: 'Avenir-Bold',
          fontSize: '24px',
          lineHeight: '25px',
          textTransform: 'uppercase',
          marginTop: '25px',
        }}
      >
        KARTA
      </Typography>
      {objectData.Latitude === '' || objectData.Longitude === '' ? (
        <>
          <Typography
            sx={{
              paddingLeft: '20px',
              paddingTop: '20px',
            }}
          >
            Kartan kunde inte laddas.
          </Typography>
        </>
      ) : (
        <Box
          className="cross-browser-height-2"
          sx={{
            marginTop: '50px',
            position: 'relative',
          }}
        >
          <MapWithNoSSR
            latitude={objectData.Latitude}
            longitude={objectData.Longitude}
          />
        </Box>
      )}
    </Box>
  );

  const houseType = houseTypes.find(
    (ht) => ht.HouseTypeID === objectData.HouseTypeId
  ).Type;
  let pageTitle;
  switch (objectData.TypeID) {
    case 0:
      pageTitle = `${houseType} med ${objectData.NumberOfRooms} rok, ${objectData.Address}, ${objectData.AreaName}`;
      break;
    case 1:
      switch (objectData.HouseTypeId) {
        case 11:
          pageTitle = `${houseType} ${objectData.LotArea} m², ${objectData.Address}, ${objectData.AreaName}`;
          break;
        default:
          pageTitle = `${houseType} med ${objectData.NumberOfRooms} rum, ${objectData.Address}, ${objectData.AreaName}`;
          break;
      }
  }

  const hero = (
    <Box
      sx={{
        width: '100%',
        height: '500px',

        backgroundImage: `url('${objectImages[0]?.FileURL}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      {objectData.Status === 5 && (
        <Box sx={{ position: 'absolute' }}>
          <Box
            sx={{
              zIndex: 1,
              marginTop: { xs: '16px', lg: '20px' },
              marginLeft: { xs: '16px', lg: '20px' },
              padding: '5px 21px',
              color: 'rgb(255, 255, 255)',
              backgroundColor: 'rgb(49, 57, 76)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              component="p"
              className="font-Avenir-Regular"
              sx={{
                pt: '3px',
                fontSize: { xs: '12px', lg: '14px' },
                textTransform: 'uppercase',
              }}
            >
              KOMMANDE
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )

  const contactForm = (
    <Box id="kontakt">
      <ContactForm
        status={objectData.Status}
        baseId={objectData.BaseID}
        data={objectData.broker}
        message={contactMessage}
        seller={objectData.seller}
        tomt={objectData.HouseTypeId === 11}
      />
    </Box>
  )

  const menu = (
    <Box
      sx={{
        backgroundColor: '#E5E5E5',
        top: '0px',
        position: 'sticky',
        zIndex: '1500',
      }}
    >
      <Container maxWidth="lg">
        <Scrollspy
          className="cross-browser-width-1"
          items={['bilder', 'planritning', 'fakta', 'karta', 'kontakt']}
          currentClassName="is-current"
          style={{
            display: 'flex',
            height: '45px',
            backgroundColor: '#E5E5E5',
            justifyContent: 'space-around',
            alignItems: 'center',
            overflow: 'auto',
            padding: '0px',
            paddingLeft: '15px',
            paddingRight: '10px',
            margin: '0px',
          }}
        >
          {![7, 9].includes(objectData.Status) &&
            menuItem('BILDER', 'bilder')}
          {![7, 9].includes(objectData.Status) &&
            menuItem('planritning', 'planritning')}
          {![7, 9].includes(objectData.Status) && menuItem('Fakta', 'fakta')}
          {menuItem('Karta', 'karta')}
          {menuItem('Kontakt', 'kontakt')}
        </Scrollspy>
      </Container>
    </Box>
  )

  const rightSidebar = (
    <Container
      maxWidth="lg"
      sx={{ zIndex: '1600', position: 'relative', width: '100%' }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '0px',
          top: '-100px',
          width: '500px',
          boxShadow: 5,
        }}
      >
        <Box
          p={3}
          m={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor:
              bidding?.biddingOngoing === true ? '#9CC5AC' : '#e6ede6',
          }}
        >
          {bidding?.biddingOngoing === true ? (
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir',
                  fontSize: '24px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  color: '#2F2E41',
                }}
              >
                {bidding?.biddingOngoing === true &&
                  (bidding.highestBid === null
                    ? 'Budgivning Pågår'
                    : 'HÖGSTA BUD')}
              </Typography>
              <Typography
                sx={{
                  marginTop: '20px',
                  fontFamily: 'Avenir-Bold',
                  fontSize: '30px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  color: '#2F2E41',
                }}
              >
                {bidding &&
                  bidding?.highestBid !== null &&
                  numberWithSpaces(bidding.highestBid) + ' kr'}
              </Typography>
              <Typography
                sx={{
                  marginTop: '22px',
                  fontFamily: 'Avenir',
                  fontSize: '18px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  color: '#2F2E41',
                }}
              >
                {`${objectData.PriceComment} ${numberWithSpaces(objectData.Price)} kr`}
              </Typography>
              {demoBids.length > 0 && (
                <Typography
                  sx={{
                    marginTop: '11px',
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '25px',
                    textDecoration: 'underline',
                    color: '#2F2E41',
                    cursor: 'pointer',
                  }}
                  onClick={() => setBidsOpened(!bidsOpened)}
                >
                  {`Se fler bud >`}
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              {objectData.Status === 9 && (
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    lineHeight: '25px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  Såld
                </Typography>
              )}
              {objectData.Status === 7 && (
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    lineHeight: '35px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  Såld eller nedtagen på säljarens begäran
                </Typography>
              )}
              {![7, 9].includes(objectData.Status) && (
                <>
                  {objectData.Price ? (
                    <>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir',
                          fontSize: '24px',
                          lineHeight: '25px',
                          textTransform: 'uppercase',
                          color: '#2F2E41',
                        }}
                      >
                        {objectData.PriceComment}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: '20px',
                          fontFamily: 'Avenir-Bold',
                          fontSize: '30px',
                          lineHeight: '25px',
                          textTransform: 'uppercase',
                          color: '#2F2E41',
                        }}
                      >
                        {numberWithSpaces(objectData.Price ?? 0)} kr
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir',
                          fontSize: '24px',
                          lineHeight: '25px',
                          textTransform: 'uppercase',
                          color: '#2F2E41',
                        }}
                      >
                        Pris saknas
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          {objectData.IsBroker === 1 && (
            <>
              {bidding?.allowOnlineBidding && (
                <Box
                  sx={{ marginLeft: 'auto' }}
                  onClick={() => setBiddingModal(true)}
                >
                  <BlackButton
                    fontFamily="Lato"
                    width="128px"
                    height="41px"
                    fontSize="17px"
                    name="Lägg bud"
                  />
                </Box>
              )}
              <Modal
                open={biddingModal}
                onClose={() => setBiddingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                sx={{
                  zIndex: '1700',
                }}
              >
                <Fade in={biddingModal}>
                  <Box
                    sx={{
                      mt: '5vh',
                      ml: '10%',
                      height: '90vh',
                      overflow: 'auto',
                      zIndex: '1700',
                      position: 'relative',
                      width: '80%',
                      bgcolor: 'background.paper',
                      border: '0px solid #000',
                      boxShadow: 24,
                      px: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      backgroundColor: ' rgb(227, 237, 228)',
                      outlineWidth: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'sticky',
                        top: '0px',
                        pt: '15px',
                        pb: '15px',
                        width: '100%',
                        backgroundColor: ' rgb(227, 237, 228)',
                      }}
                    >
                      <CloseIcon
                        sx={{ ml: 'auto', cursor: 'pointer' }}
                        onClick={() => setBiddingModal(false)}
                      />
                    </Box>
                    <iframe
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      src={`${process.env.NEXT_PUBLIC_BIDDING_IFRAME_URL}/${objectData.BaseID}`}
                    />
                  </Box>
                </Fade>
              </Modal>
            </>
          )}
        </Box>
        {!bidding?.biddingOngoing && (
          <>
            <Divider sx={{ backgroundColor: '#515151' }} />
          </>
        )}

        <Box
          px={3}
          m={0}
          sx={{
            overflow: 'hidden',
            backgroundColor: '#9CC5AC',
            maxHeight:
              bidsOpened && refContainer.current !== null
                ? `${(refContainer.current as any).offsetHeight + 80}px`
                : '0px',
          }}
        >
          <Box ref={refContainer}>
            <TableContainer
              sx={{
                backgroundColor: '#9CC5AC',
                border: '0px',
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Bud</TableCell>
                    <TableCell>Datum</TableCell>
                    <TableCell align="right">Budgivare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demoBids
                    .slice(0, demoBids.length < 5 ? demoBids.length : 5)
                    .map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          textDecoration:
                            row.nBidStatusId === 2 ? 'line-through' : 'none',
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          {numberWithSpaces(row.price) + ' KR'}
                        </TableCell>
                        <TableCell padding="normal">{`${row.date.getDate()}/${
                          row.date.getMonth() + 1
                        } ${
                          row.date.getHours() > 9
                            ? row.date.getHours()
                            : '0' + row.date.getHours()
                        }:${
                          row.date.getMinutes() > 9
                            ? row.date.getMinutes()
                            : '0' + row.date.getMinutes()
                        }`}</TableCell>
                        <TableCell padding="normal" align="right">
                          {row.bidder +
                            (row.bStopped ? ' (stannat)' : '') +
                            (row.nBidStatusId === 2 ? ' (återkallat)' : '')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {demoBids.length > 5 && (
              <Typography
                sx={{
                  fontFamily: 'Avenir',
                  fontSize: '18px',
                  lineHeight: '25px',
                  textDecoration: 'underline',
                  color: '#2F2E41',
                  cursor: 'pointer',
                  textAlign: 'center',
                  marginBottom: '30px',
                }}
                onClick={() => setBidsModal(true)}
              >
                {`Visa hela historiken på ${demoBids.length} bud >`}
              </Typography>
            )}
          </Box>
        </Box>
        <Modal
          open={bidsModal}
          onClose={() => setBidsModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            zIndex: '1700',
          }}
        >
          <Fade in={bidsModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                boxShadow: 24,
                backgroundColor: '#9CC5AC',
                p: 4,
                height: '500px',
                overflowY: 'scroll',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'black',
                  }}
                >
                  Budhistorik
                </Typography>
                <Box
                  sx={{
                    ml: 'auto',
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  onClick={() => setBidsModal(false)}
                >
                  <CloseIcon />
                </Box>
              </Box>

              <TableContainer
                sx={{
                  border: '0px',
                  color: 'white',
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bud</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell align="right">Budgivare</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demoBids.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          textDecoration:
                            row.nBidStatusId === 2 ? 'line-through' : 'none',
                          textDecorationColor: 'black',
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {numberWithSpaces(row.price)}
                        </TableCell>
                        <TableCell>{`${row.date.getDate()}/${
                          row.date.getMonth() + 1
                        } ${('0'+row.date.getHours()).slice(-2)}:${('0'+row.date.getMinutes()).slice(-2)}`}</TableCell>
                        <TableCell align="right">{row.bidder +
                          (row.bStopped ? ' (stannat)' : '') +
                          (row.nBidStatusId === 2 ? ' (återkallat)' : '')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Fade>
        </Modal>

        <Box sx={{ backgroundColor: '#E6EdE6' }}>
          {objectData.IsBroker === 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box p={3} sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '20px',
                    lineHeight: '20px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  SÄLJES UTAN MÄKLARE
                </Typography>
                <Link href="/salja#annons">
                  (Läs mer om vår Annonstjänst här)
                </Link>
                <Typography
                  sx={{
                    marginTop: '18px',
                    fontFamily: 'Lato',
                    fontSize: '20px',
                    lineHeight: '25px',
                    color: '#2F2E41',
                  }}
                >
                  {objectData.seller.firstName +
                    ' ' +
                    objectData.seller.lastName}
                </Typography>

                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <AnchorLink
                      href="#kontakt"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        width="148px"
                        height="45px"
                        fontSize="17px"
                        name="Kontakta"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {objectData.broker && objectData.broker.bitBrokerActive && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                height="200px"
                width="200px"
                sx={{
                  backgroundImage: `url('https://static2.privatmaklaren.se/brokers/${objectData.broker.nBrokerId}.jpg')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <Box p={3} sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '20px',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  {objectData.broker.strBrokerText}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '6px',
                    fontFamily: 'Lato',
                    fontSize: '20px',
                    lineHeight: '25px',
                    color: '#2F2E41',
                  }}
                >
                  {objectData.broker.strBrokerName}
                </Typography>

                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <AnchorLink
                      href="#kontakt"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        width="128px"
                        height="41px"
                        fontSize="17px"
                        name="Kontakta"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {![7, 9].includes(objectData.Status) && (
          <>
            <Divider sx={{ backgroundColor: '#515151' }} />
            {objectData.showings.length > 0 ? (
              <Box p={3} sx={{ backgroundColor: '#e6ede6' }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    lineHeight: '25px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  Visning
                </Typography>
                {[...objectData.showings]
                  .sort((a, b) => (a.TimeStart > b.TimeStart ? 1 : -1))
                  .filter((item) => {
                    const timeEnd = new Date(item.TimeEnd);
                    if (timeEnd > new Date()) return 1;
                    return -1;
                  })
                  .map((item, i) => {
                    const startTime = new Date(item.TimeStart);
                    const endTime = new Date(item.TimeEnd);

                    return (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '20px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Lato-Light',
                            fontSize: '20px',
                            color: '#2F2E41',
                          }}
                        >
                          {swedishDay(startTime.getDay()) +
                            ' ' +
                            startTime.getDate() +
                            ' ' +
                            swedishMonth(startTime.getMonth()) +
                            ' kl. ' +
                            ('0' + startTime.getHours()).slice(-2) +
                            '.' +
                            ('0' + startTime.getMinutes()).slice(-2) +
                            '-' +
                            ('0' + endTime.getHours()).slice(-2) +
                            '.' +
                            ('0' + endTime.getMinutes()).slice(-2)}
                        </Typography>

                        <Box
                          sx={{ marginLeft: 'auto' }}
                          onClick={() =>
                            setContactMessage(
                              `Jag önskar komma på visningen ${
                                swedishDay(startTime.getDay()) +
                                ' ' +
                                startTime.getDate() +
                                ' ' +
                                swedishMonth(startTime.getMonth()) +
                                ' kl. ' +
                                ('0' + startTime.getHours()).slice(-2) +
                                '.' +
                                ('0' + startTime.getMinutes()).slice(-2) +
                                '-' +
                                ('0' + endTime.getHours()).slice(-2) +
                                '.' +
                                ('0' + endTime.getMinutes()).slice(-2)
                              }.`
                            )
                          }
                        >
                          <AnchorLink
                            href="#kontakt"
                            offset={45}
                            style={{ textDecoration: 'none' }}
                          >
                            <BlackButton
                              fontFamily="Lato"
                              width="128px"
                              height="41px"
                              fontSize="17px"
                              name="Boka"
                            />
                          </AnchorLink>
                        </Box>
                      </Box>
                    );
                  })}
                {objectData.ShowingComment && (
                  <Typography
                    sx={{
                      marginTop: '20px',
                      fontFamily: 'Lato-Light',
                      fontSize: '20px',
                    }}
                  >
                    {objectData.ShowingComment}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    px: 3,
                    py: 2,
                    backgroundColor: '#e6ede6',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Lato-Light',
                      fontSize: '20px',
                      color: '#2F2E41',
                    }}
                  >
                    {objectData.ShowingComment
                      ? objectData.ShowingComment
                      : 'Hos Privatmäklaren visar ägaren själv. Boka visning här. '}
                  </Typography>

                  <Box
                    sx={{ marginLeft: 'auto' }}
                    onClick={() =>
                      setContactMessage(`Intresseanmälan för visning`)
                    }
                  >
                    <AnchorLink
                      href="#kontakt"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        width="128px"
                        height="41px"
                        fontSize="17px"
                        name="Boka"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        <Divider sx={{ backgroundColor: '#515151' }} />
        <Box
          p={3}
          sx={{
            display: 'flex',
            height: '54px',
            alignItems: 'center',
            backgroundColor: '#e6ede6',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir-Book',
              fontSize: '24px',
              lineHeight: '25px',
            }}
          >
            DELA
          </Typography>
          <a
            rel="noreferrer"
            href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
              process.env.NEXT_PUBLIC_DOMAIN_URL + '/' + objectData.Slug
            )}`}
            target="_blank"
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <Box component="img" src={'/images/footerFBB.svg'} ml="20px" />
          </a>

          <a
            rel="noreferrer"
            href={`mailto:?subject=${emailShareSubject}&body=${emailShareBody}`}
            target={'_blank'}
          >
            <Box component="img" src={'/images/shareEmailB.svg'} ml="20px" />
          </a>
        </Box>
      </Box>
    </Container>
  )

  const description = (
    <Fragment>
    {![7, 9].includes(objectData.Status) && (
      <Box
        className="cross-browser-width-2"
        sx={{
          mt: '20px',
          height: showObjectGeneral
            ? `${Math.max(
              refObjectGeneral.current.offsetHeight + 20,
              700
            )}px`
            : '700px',
          px: '15px',
          overflow: 'hidden',
          position: 'relative',
          transition: '0.2s all ease-in-out',
        }}
      >
        {refVisible && refObjectGeneral.current.offsetHeight > 680 && !showObjectGeneral && (
          <Box
            className="cross-browser-height-1"
            sx={{
              position: 'absolute',
              width: '100%',
              background: 'linear-gradient(#fff0 70%, #fff 96%)',
              opacity: showObjectGeneral ? 0 : 1,
              display: 'flex',
              alignItems: 'flex-end',
              transition: '.2s all ease-in-out',
            }}
          >
            {!showObjectGeneral && (
              <Typography
                component="span"
                sx={{
                  marginTop: '16px',
                  fontFamily: 'Avenir',
                  fontSize: '14px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  display: 'block'
                }}
                onClick={() => setShowObjectGeneral(!showObjectGeneral)}
              >
                {`Hela Beskrivningen >`}
              </Typography>
            )}
          </Box>
        )}
        <Box
          ref={(el) => {
            (refObjectGeneral.current = el), setRefVisible(!!el);
          }}
        >
          <Typography
            component="span"
            sx={{
              marginTop: '10px',
              fontFamily: 'Avenir-Bold',
              fontSize: '24px',
              lineHeight: '25px',
              textTransform: 'uppercase',
              position: 'relative',
              display: 'block'
            }}
          >
            {objectData.Address}
          </Typography>
          <Box sx={{ mt: '8px', display: 'flex', position: 'relative' }}>
            <Grid container>
              {objectGeneral.map(
                (item, i) =>
                  item.value !== null &&
                  item.value !== '' &&
                  item.value != undefined && (
                    <Grid item xs={6} key={i}>
                      <Box key={i} sx={{ mt: '17px' }}>
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: 'Avenir',
                            fontSize: '18px',
                            lineHeight: '25px',
                            textTransform: 'uppercase',
                            color: '#2F2E41',
                            display: 'block'
                          }}
                        >
                          {item.type}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            mt: '5px',
                            fontFamily: 'Avenir',
                            fontSize: '18px',
                            lineHeight: '25px',
                            color: '#2F2E41',
                            display: 'block'
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>

          <Typography
            component="span"
            sx={{
              marginTop: '55px',
              fontFamily: 'Avenir-Bold',
              fontSize: '24px',
              lineHeight: '25px',
              textTransform: 'uppercase',
              position: 'relative',
              display: 'block'
            }}
          >
            {objectData.Headline}
          </Typography>
          <Typography
            component="span"
            sx={{
              marginTop: '21px',
              fontFamily: 'Lato-Light',
              fontSize: '20px',
              lineHeight: '25px',
              whiteSpace: 'pre-wrap',
              display: 'block'
            }}
          >
            {objectData.BriefDescription}
          </Typography>
        </Box>
      </Box>
    )}
    </Fragment>
  )

  const summary = (
    <Fragment>
    {[7, 9].includes(objectData.Status) && (
      <>
      <Typography
        sx={{
          marginTop: '10px',
          fontFamily: 'Avenir-Bold',
          fontSize: '24px',
          lineHeight: '25px',
          textTransform: 'uppercase',
        }}
      >
        {objectData.Address}
      </Typography>
      <Box sx={{ pl: '15px', pr: '500px', mt: '8px', display: 'flex' }}>
        <Grid container>
          {objectGeneral.map(
            (item, i) =>
              item.value !== null &&
              item.value !== '' &&
              item.value != undefined && (
                <Grid item xs={6} key={i}>
                  <Box key={i} sx={{ mt: '17px' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir',
                        fontSize: '18px',
                        lineHeight: '25px',
                        textTransform: 'uppercase',
                        color: '#2F2E41',
                      }}
                    >
                      {item.type}
                    </Typography>
                    <Typography
                      sx={{
                        mt: '5px',
                        fontFamily: 'Avenir',
                        fontSize: '18px',
                        lineHeight: '25px',
                        color: '#2F2E41',
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
      </Box>
    </>
    )}
      </Fragment>
  )

  const images = (
    <Box
      sx={{
        marginBottom: "1rem"
      }}
    >
    {objectImages.length !== 0 && ![7, 9].includes(objectData.Status) && (
        <>
          <ImageList
            id="bilder"
            sx={{ width: '100%', mt: '30px', cursor: 'pointer' }}
            variant="quilted"
            cols={3}
            rowHeight={250}
            onClick={() => setImagesModalOpen(1)}
          >
            {itemData.map((item, i) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                {item.img ? (
                  <img
                    {...srcset(item.img, 382, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#DDD',
                    }}
                  ></Box>
                )}
                {i === 5 &&
                  objectImages.filter((i) => i.Type === 0).length > 6 && (
                    <Box
                      sx={{
                        top: 0,
                        backgroundColor: '#00000084',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto-Bold',
                          fontSize: '26px',
                          color: 'white',
                        }}
                      >
                        {'+ '}
                        {objectImages.filter((i) => i.Type === 0).length -
                          6}
                      </Typography>
                    </Box>
                  )}
              </ImageListItem>
            ))}
          </ImageList>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '33px',
          }}
        >
          <BlackButton
            fontFamily="Lato"
            width="187px"
            height="58px"
            fontSize="17px"
            name="Alla bilder"
            onClick={() => setImagesModalOpen(1)}
          />
        </Box>
        <Modal
          open={imagesModalOpen !== 0}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            zIndex: '1700',
          }}
        >
          <Fade in={imagesModalOpen !== 0}>
            <Box
              sx={{
                height: '100vh',
                overflow: 'auto',
                zIndex: '1700',
                position: 'relative',
                width: '100%',
                bgcolor: 'background.paper',
                border: '0px solid #000',
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: ' rgb(250, 246, 250)',
                outlineWidth: 0,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  position: 'sticky',
                  top: '0px',
                  width: '100%',
                  bgcolor: 'rgba(250, 246, 250, .7)',
                  boxShadow: "0 0 #0000, 0 0 #0000, 0 2px 4px 0 rgba(249, 218, 233, .5)"
                }}
              >
                <Box
                  sx={{
                      p: 2
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Roboto-Bold'
                    }}
                  >
                    {objectData.AreaName}, {municipalities.find(
                    (municipality) =>
                      municipality.MunicipalityID === objectData.MunicipalityID
                  ).Name}
                  </Typography>
                  <Typography>{objectData.Address}</Typography>
                </Box>
                <CloseIcon
                  sx={{ ml: 'auto', cursor: 'pointer' }}
                  onClick={() => setImagesModalOpen(0)}
                />
              </Box>
              {objectImages
                .filter((i) => i.Type >= imagesModalOpen - 1)
                .map((item, i) => (
                  <Box key={i} sx={{maxWidth: "1024px"}}>
                    <Box
                      component="img"
                      src={item.FileURL + '?w=1024'}
                      alt={item.title}
                      maxWidth={'1024px'}
                      loading="lazy"
                      sx={{
                        marginBottom: '15px',
                        maxHeight: "100vh"
                    }}
                    />
                    <Typography
                      sx={{
                        marginBottom: '30px',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                      }}
                    >
                      {item.ImageText}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Fade>
        </Modal>
          </>
      )}
    </Box>
  )

  const floorPlans = (
    <Fragment>
      {![7, 9].includes(objectData.Status) && (
        <Box id="planritning">
          <Box py={3} mt={'50px'}>
            <Typography
              sx={{
                fontFamily: 'Avenir-Bold',
                fontSize: '24px',
                textTransform: 'uppercase',
              }}
            >
              Planritning
            </Typography>
            {objectImages.find((i) => i.Type === 1) ? (
              // eslint-disable-next-line @next/next/no-img-element
              <>
                <Grid container>
                  {objectImages
                    .filter((i) => i.Type === 1)
                    .map((item, i) => (
                      <Grid key={i} item xs={6} md={6} sx={{ p: 2 }}>
                        <img
                          src={item.FileURL + '?w=1024'}
                          alt="planritning"
                          style={{
                            marginTop: '14px',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                          onClick={() => setImagesModalOpen(2)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: 18 }}>
                  Planritningar saknas
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Fragment>
  )

  const facts = (
    <Fragment>
      {![7, 9].includes(objectData.Status) && (
        <Box id="fakta">
          <Box py={3} mt={'50px'}>
            <Typography
              sx={{
                fontFamily: 'Avenir-Bold',
                fontSize: '24px',
                lineHeight: '25px',
                textTransform: 'uppercase',
              }}
            >
              FAKTA
            </Typography>
          </Box>
          <Accordion data={accordionData} />
        </Box>
      )}
    </Fragment>
  )

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta
          property="og:image"
          content={`https://static2.privatmaklaren.se/previews/${objectData.BaseID}.jpg`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={objectData.BriefDescription} />
      </Head>
      {hero}
      {menu}
      {rightSidebar}
      <Container maxWidth="lg">
        <Box>
          {description}
          {summary}
          {images}
          <YouTubeVideos object={objectData} />
        </Box>
        {floorPlans}
        {facts}
        {map}
        {contactForm}
      </Container>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    houseAssociationTypes: state.main.houseAssociationTypes,

    coaAssociationTypes: state.main.coaAssociationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    biddingInfo: state.main.biddingInfo,
  };
};

export default connect(mapStateToProps, null)(HouseInfo);
